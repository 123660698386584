<template>
  <div>
    <b-field grouped group-multiline>
      <button type="button" class="button is-primary is-default" @click="addLicenseRow">Add License</button>
      <b-field label="" v-if="checkedResults.length">
        <b-select name="actionsSelect" v-model="withAction" @input="onWithChecked()" placeholder="With Selected">
          <option value="ack">{{ labelRead }}</option>
          <option value="unack">{{ labelUnRead }}</option>
        </b-select>
      </b-field>
    </b-field>
    <b-table :striped="true" :data="licenseOrders" :checked-rows.sync="checkedResults" :checkable="true" narrowed :opened-detailed="defaultOpenedDetails" :detailed="detail" detail-key="id">
      <template v-slot:empty>
        No Licenses
      </template>
      <template v-slot="props">
        <b-table-column label="Id" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <div v-if="props.row.id">{{ props.row.id }}</div>
        </b-table-column>
        <b-table-column label="License Number" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <div v-if="props.row.id">{{ props.row.license_number }}</div>
          <div v-else class="form-group">
            <input type="text" :class="props.row.license_number ? 'form-control' : 'form-control required'" v-model.trim="props.row.license_number" placeholder="License Number" />
            <div class>
              <div class="help is-danger" v-if="!isValidNumber(props.row)">Number must have at least {{ licenseNumberMinLength }} letters.</div>
            </div>
          </div>
        </b-table-column>
        
        <b-table-column v-if="subjectType == 'O'" label="License Type" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <div v-if="props.row.id">{{ props.row.license_type }}</div>
          <select v-else :class="props.row.license_type ? 'form-control' : 'form-control required'" v-model.trim="props.row.license_type" :disabled="props.row.monitoringProductId">
            <option value disabled>(License Type)</option>
            <option v-for="(licenseType, key) in td_constant.TYPH_DATA_ORG_LICENSE_TYPES" :value="key" :key="key">{{ licenseType }}</option>
          </select>
        </b-table-column>
        <b-table-column v-else label="License Type" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <div v-if="props.row.id">{{ props.row.license_type }}</div>
          <select v-else :class="props.row.license_type ? 'form-control' : 'form-control required'" v-model.trim="props.row.license_type" :disabled="props.row.monitoringProductId">
            <option value disabled>(License Type)</option>
            <option v-for="(licenseType, key) in td_constant.TYPH_DATA_LICENSE_TYPES" :value="key" :key="key">{{ licenseType }}</option>
          </select>
        </b-table-column>
        <b-table-column label="License State" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <div v-if="props.row.id">{{ props.row.license_state }}</div>
          <select v-else :class="props.row.license_state ? 'form-control' : 'form-control required'" v-model.trim="props.row.license_state" :disabled="props.row.monitoringProductId">
            <option value disabled>(License State)</option>
            <option v-for="(jurisdiction, key) in td_constant.TYPH_DATA_JURISDICTIONS" :value="key" :key="key">{{ jurisdiction }}</option>
          </select>
        </b-table-column>
        

        <b-table-column label="License Status" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <div>{{ props.row.license_status }}</div>
        </b-table-column>
        <b-table-column label="Found Status" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <div>{{ props.row.found_status }}</div>
        </b-table-column>
        <b-table-column v-if="!props.row.id || monitoring" label="">
          <button type="button" class="button is-danger is-small" @click="removeLicense(props.row.index)">REMOVE</button>
        </b-table-column>
        <b-table-column v-if="!props.row.id && monitoring && subjectId" label="">
          <button @click="props.row.id = appendLicense(props.row.index)" class="button is-primary is-small" id="next_button" :disabled="!isValid(props.row)">Place Order</button>
        </b-table-column>
      </template>
      <template v-slot:detail="props">
        <LicenseResultDetail :isMonitoring="true" :subjectId="props.row.monitoring_subject_id" :productOrderId="props.row.id" />
      </template>
    </b-table>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LicenseResultDetail from '@/components/bryce/LicenseResultDetail.vue'

export default {
  components: {
    LicenseResultDetail
  },
  props: {
    detail: {
      type: Boolean,
      default: false
    },
    subjectType: {
      type: String,
      required: true
    },
    monitoring: {
      type: Boolean,
      default: false
    },
    subjectId: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      licenseNumberMinLength: 4,

      withAction: null,
      checkedResults: [],
      defaultOpenedDetails: [],
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 20
    }
  },
  computed: {
    ...mapGetters('product_order', ['licenseOrders']),
    ...mapState(['product_order', 'td_constant'])
  },
  methods: {
    addLicenseRow() {
      this.$store.dispatch('product_order/pushProductOrder', {})
    },
    appendLicense(i) {
      return this.$store.dispatch('product_order/addLicenseOrder', { index: i, subjectId: +this.subjectId, monitoring: this.monitoring })
    },
    isValid(license) {
      return this.isValidNumber(license) && license.license_type && license.license_state
    },
    isValidNumber(license) {
      return license.license_number && license.license_number.length >= this.licenseNumberMinLength
    },
    onMarkAsAck(license) {
      if (!license.ack) {
        this.$store.dispatch('action_result/resultMarkAsRead', { licenseResultId: license.license_result_id, subjectId: license.subject_id, isMonitoring: true }).then(() => {
          license.ack = true
        })
      }
    },
    removeLicense(i) {
      this.$store.dispatch('product_order/removeLicenseOrder', { index: i })
    }
  }
}
</script>

<style scoped>
input {
  border: 1px solid silver;
  border-radius: 4px;
  background: white;
  padding: 5px 10px;
}
.required {
  border-color: red;
}
</style>
