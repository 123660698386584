<template>
  <section class="">
    <TdPanel panelId="search_results" panelTitle="Groups">
      <div class="panel-body">
        <b-table
          :striped="true"
          :data="sortedGroups"
          :current-page.sync="currentPage"
          narrowed
          :paginated="true"
          :pagination-simple="false"
          :per-page="perPage"
          :default-sort-direction="defaultSortDirection"
          default-sort="id"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
        >
          <template v-slot:empty>
            No customers
          </template>
          <template v-slot="props">
            <b-table-column field="name" label="Name" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="total_pending" label="Total Pending" sortable>
              {{ props.row.total_pending }}
            </b-table-column>
          </template>
          <template v-slot:detail="props">
            <GroupView :id="props.row.id" :group_info="props.row" />
          </template>
        </b-table>
      </div>
    </TdPanel>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import GroupView from '@/views/bryce/GroupView.vue'

export default {
  props: {},
  data() {
    return {
      status: null,

      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 150,
      perPageDownloadCount: 400,
      defaultOpenedDetails: [],
      pageNum: 1,
      pageCount: 250
    }
  },
  computed: {
    ...mapGetters('group', ['sortedGroups'])
  },
  components: {
    GroupView
  },
  created() {
    if (this.$store.getters['auth_user/hasAdmin']) {
      this.$store.dispatch('group/fetchGroups', {})
    }
  },
  methods: {
  }
}
</script>

<style scoped></style>
