<template>
  <div>
    <TdPanel panelId="enroll_history" panelTitle="Product Enroll History" :minivied="minivied">
      <b-table
        :striped="true"
        :data="enrollHistories"
        :current-page.sync="currentPage"
        narrowed
        :paginated="true"
        :pagination-simple="false"
        :per-page="perPage"
        :default-sort-direction="defaultSortDirection"
        default-sort="name"
      >
        <template v-slot:empty>
          No history
        </template>
        <template v-slot="props">
          <b-table-column field="monitoring_product_id" label="Product ID" sortable>
            {{ props.row.monitoring_product_id }}
          </b-table-column>
          <b-table-column field="monitoring_license_id" label="License ID" sortable>
            {{ props.row.monitoring_license_id }}
          </b-table-column>
          <b-table-column field="batch_name" label="Batch ID" sortable>
            <router-link :to="{ name: 'batch-view', query: { id: props.row.batch_id, monitoring: true } }">{{ props.row.batch_id }}</router-link>
          </b-table-column>
          <b-table-column field="batch_name" label="Batch" sortable>
            {{ props.row.batch_name }}
          </b-table-column>
          <b-table-column field="action" label="Action" sortable>
            {{ props.row.action }}
          </b-table-column>
          <b-table-column field="created_at" label="Date" sortable>
            {{ props.row.created_at | shortNoTz }}
          </b-table-column>
        </template>
      </b-table>
    </TdPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    minivied: {
      default: true,
      required: false
    },
    subjectId: {
      type: [String, Number],
      required: true
    }
  },
  data() {
    return {
      enrollHistories: [],
      defaultSortDirection: 'desc',
      currentPage: 1,
      perPage: 25
    }
  },
  computed: {
    ...mapGetters('auth_user', ['currentUser'])
  },
  components: {},
  created() {},
  mounted() {
    this.$store
      .dispatch('monitoring/fetchProductEnrollHistory', {
        searchOptions: { monitoring_subject_id: +this.subjectId, perPage: this.perPage, page: this.currentPage }
      })
      .then(results => {
        this.enrollHistories = []
        for (let i in results) {
          this.enrollHistories.push(results[i])
        }
      })
  }
}
</script>

<style scoped></style>
