<template>
  <div>
    <!-- {{ products }} -->
    <TdPanel panelId="products" panelTitle="Products">
      <b-table
        :striped="true"
        :data="products"
        :current-page.sync="currentPage"
        narrowed
        :paginated="false"
        :pagination-simple="false"
        :per-page="perPage"
        :default-sort-direction="defaultSortDirection"
        default-sort="name"
      >
        <template v-slot:empty>
          No products
        </template>
        <template v-slot="props">
          <b-table-column field="enabled" label="Enabled">
            <b-checkbox v-model="props.row.is_enabled" @input="updateProductEnabled(props.row.id)"> </b-checkbox>
          </b-table-column>
          <b-table-column field="name" label="Product">
            {{ props.row.name }}
          </b-table-column>
        </template>
      </b-table>
    </TdPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    customerId: {
      required: true
    }
  },
  data() {
    return {
      products: [],

      defaultOpenedDetails: [],
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 125
    }
  },
  computed: {
    ...mapGetters('auth_user', ['isTDStaff'])
  },
  components: {},
  created() {
    this.$store.dispatch('customer/fetchCustomerProducts', { customerId: this.customerId }).then(products => {
      this.products = products
    })
  },
  methods: {
    updateProductEnabled(productId) {
      this.$store.dispatch('customer/updateProductEnabled', { customerId: this.customerId, productId: productId })
    }
  }
}
</script>

<style scoped></style>
