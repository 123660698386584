<template>
  <div style="background:#e7e7e7;height:100%">
    <div class="has-text-centered">
      <figure class="" style="">
        <img :src="logoImagePath" style="width:25%;height:25%" alt="Brand" />
      </figure>
    </div>
    <div class="box2">
      <h3 class="title is-4" style="color: #000000; text-transform: none; font-family:'Open Sans';">CDaaS Results</h3>
      <p class="subtitle has-text-black" style="font-family:'Open Sans';">Please enter at least 3 search criteria to get results</p>
    
      <div class="box is-shadowless" :class="brandName">
        <CdaasLandingForm :referenceID="referenceID" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CdaasLandingForm from '@/components/shared/CdaasLandingForm.vue';

export default {
  props: {
    referenceID: {
      type: String,
      default: null
    }
  },
  computed: {
    logoImagePath() {
      return '/assets/images/cdaas_logo.png';
    },
    ...mapState('layout', ['brandName'])
  },
  components: {
    CdaasLandingForm
  },
  methods: {
    downloadFile(data, filename, type) {
      console.log('downloadFile called with type:', type);
      if (typeof data === 'json') {
        console.log('Calling downloadJsonFile');
        this.downloadJsonFile(data, filename);
      } else {
        // Existing process for other content types
        let byteCharacters;
        if (typeof data === 'string') {
          byteCharacters = atob(data);
          const byteArrays = [];
          for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
              byteNumbers[i] = slice.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
          }
          data = new Blob(byteArrays, { type });
        } else {
          data = new Blob([data], { type });
        }
        const url = window.URL.createObjectURL(data);
        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        document.body.removeChild(a);
      }
    },
    downloadJsonFile(data, filename) {
      console.log('downloadJsonFile called');
      let jsonString;
      if (typeof data === 'string') {
        try {
          const decodedString = atob(data);
          jsonString = JSON.stringify(JSON.parse(decodedString), null, 2);
        } catch (e) {
          console.error('Error parsing JSON:', e);
          return;
        }
      } else {
        jsonString = JSON.stringify(data, null, 2);
      }
      const blob = new Blob([jsonString], { type: 'application/json' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
    },
    handleFileDownload(response, filename) {
      console.log('handleFileDownload called with filename:', filename);
      let contentType = response.headers['content-type'] || 'application/octet-stream';
      if (filename.endsWith('.zip')) {
        contentType = 'application/zip';
      } else if (contentType === 'text/plain' && !filename.endsWith('.txt')) {
        filename += '.txt';
      } else if (contentType === 'application/pdf' && !filename.endsWith('.pdf')) {
        filename += '.pdf';
      } else if (contentType === 'application/json' && !filename.endsWith('.json')) {
        filename += '.json';
      }
      console.log('Determined contentType:', contentType);
      this.downloadFile(response.data, filename, contentType);
    }
  }
};
</script>

<style scoped>
.hds,
.iqvia,
.sjv {
  background: white;
}

.typhoon {
  background: transparent;
}

/* Add any other styles you need here */
.has-text-centered { /* Example: Style the centered content */
  text-align: center;
}

.box { /* Example: Style the box */
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  text-align: center;
  width: 90%;
}
.box2 { /* Example: Style the box */
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  border-radius: 5px;
  text-align: center;
  background: #a3aaad;
  width: 50%
}
</style>