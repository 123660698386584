<template>
  <div>
    <b-collapse class="card" :aria-id="'advanced'" :open="advancedOpen" @open="onCollapse(true)" @close="onCollapse(false)" style="margin-bottom:10px">
      <template #trigger="props" class="card-header" role="button" aria-controls="contentIdForA11y3">
        <a class="card-header-icon">
          <b-icon :icon="props.open ? 'menu-down' : 'menu-up'"> </b-icon>
        </a>
      </template>
      <!-- {{ searchByFilters }} -->
      <div class="card-content">
        <div class="content">
          <div class="buttons">
            <span v-if="byId" class="button">
              <b-field label="ID">
                <b-input v-model.trim="provider_result.searchById"></b-input>
              </b-field>
            </span>
            <span v-if="byName && !isFADV" class="button">
              <b-field label="First Name">
                <b-input v-model.trim="provider_result.searchByFirstName"></b-input>
              </b-field>
            </span>
            <span v-if="byName && !isFADV" class="button">
              <b-field label="Last Name">
                <b-input v-model.trim="provider_result.searchByLastName"></b-input>
              </b-field>
            </span>
            <span v-if="byName" class="button">
              <b-field label="Organization Name">
                <b-input v-model.trim="provider_result.searchByOrganizationName"></b-input>
              </b-field>
            </span>
            <span v-if="bySsn && !isFADV" class="button">
              <b-field label="SSN">
                <b-input v-model.trim="provider_result.searchBySsn"></b-input>
              </b-field>
            </span>
            <span v-if="byDob && !isFADV" class="button">
              <b-field label="Date of Birth">
                <b-input v-model.trim="provider_result.searchByDob"></b-input>
              </b-field>
            </span>
            <span v-if="byReference" class="button">
              <b-field label="Reference">
                <b-input v-model.trim="provider_result.searchByReferenceNumber"></b-input>
              </b-field>
            </span>
            <span v-if="byUser" class="button">
              <b-field label="User">
                <b-input v-model.trim="provider_result.searchByUser"></b-input>
              </b-field>
            </span>
            <span v-if="byGroups" class="button is-outlined">
              <div class="card-content" style="padding:0px">
                <label class="label">Groups</label>
                <b-field>
                  <b-taginput
                    v-model="provider_result.searchByGroups"
                    :data="filteredTags"
                    autocomplete
                    :allow-new="false"
                    field="name"
                    icon="label"
                    :open-on-focus="true"
                    placeholder="Type to select a group"
                    @typing="getFilteredGroups"
                    ><template v-slot="props">
                      {{ props.option.name }}
                    </template>
                    <template v-slot:empty>
                      No match
                    </template></b-taginput
                  >
                </b-field>
              </div>
            </span>
            <span v-if="byDate" class="button is-td-default is-outlined">
              <div class="card-content" style="padding:0px">
                <label class="label">Date Range</label>
                <b-field>
                  <b-select v-if="false" placeholder="Pre-sets" :model="dateRangeOption" @click="dateRangeByOption()">
                    <option value>Custom</option>
                    <option value="NONE">No Date Range</option>
                    <option value="7DAYS">Last 7 Days</option>
                    <option value="WEEK">Previous Week</option>
                    <option value="MONTH">Previous Month</option>
                  </b-select>
                  <b-datepicker placeholder="Click to select..." v-model="provider_result.searchByDateRange" range icon="calendar-today">
                    <button class="button is-primary" @click="dateRangeLast7Days()">
                      <span>Last 7 Days</span>
                    </button>
                    <button class="button is-primary" @click="dateRangePreviousWeek()">
                      <span>Previous Week</span>
                    </button>
                    <button class="button is-primary" @click="dateRangePreviousMonth()">
                      <span>Previous Month</span>
                    </button>
                    <button class="button is-danger" @click="dateRangeNone()">
                      <span>No Dates</span>
                    </button>
                    <button class="button is-warning" @click="dateRangeLast30Days()">
                      <b-icon icon="close"></b-icon>
                      <span>Default</span>
                    </button>
                  </b-datepicker>
                </b-field>
              </div>
            </span>
            <span v-if="byStatus" class="button is-td-default is-outlined">
              <div class="card-content" style="padding:0px">
                <label class="label">Status</label>
                <b-field>
                  <b-select placeholder="Status" v-model="provider_result.searchByStatus">
                    <option value=""></option>
                    <option value="ALL">ALL</option>
                    <option value="ENROLLED">ENROLLED</option>
                    <option value="REMOVED">REMOVED</option>
                  </b-select>
                </b-field>
              </div>
            </span>
            <span v-if="byCountLimit" class="button">
              <b-field label="Results Max">
                <b-select v-model="provider_result.searchByCountLimit" :disabled="false" style="padding:2.5px">
                  <option value="500">500 (Fastest)</option>
                  <option value="1000">1,000</option>
                  <option value="2500">2,500</option>
                  <option value="5000">5,000 (Slower)</option>
                  <option value="10000">10,000</option>
                  <option value="15000">15,000 (Slowest)</option>
                </b-select>
              </b-field>
            </span>
            <span v-if="false && hasAdmin" class="button">
              <b-field label="ID">
                <b-input v-model.trim="id"></b-input>
              </b-field>
            </span>
            <button type="submit" class="button is-primary" id="next_button" @click="onSubmitSearchOptions">Search</button>
            <button type="reset" class="button is-default" id="next_button" @click="formReset()">Clear</button>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { formatDate, formatNumber } from '@/helpers/format_helpers'

export default {
  props: {
    byDate: {
      type: Boolean,
      default: false
    },
    byDob: {
      type: Boolean,
      default: false
    },
    byCountLimit: {
      type: Boolean,
      default: false
    },
    byGroups: {
      type: Boolean,
      default: false
    },
    byId: {
      type: Boolean,
      default: false
    },
    byName: {
      type: Boolean,
      default: false
    },
    byReference: {
      type: Boolean,
      default: false
    },
    bySsn: {
      type: Boolean,
      default: false
    },
    byStatus: {
      type: Boolean,
      default: false
    },
    byUser: {
      type: Boolean,
      default: false
    },
    includeMonitoring: {
      type: Boolean,
      default: true
    },
    includeOnetime: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      advancedOpen: true,
      clearKey: 0,
      todayDate: null,
      dateRange: [],
      dateRangeOption: null,
      filteredTags: this.$store.getters['group/sortedGroups']
    }
  },
  components: {},
  created() {
    let open = localStorage.getItem('advanced-open')
    if (open == 'true') {
      this.advancedOpen = true
    } else if (open == 'false') {
      this.advancedOpen = false
    }
    this.provider_result.searchByDateRange = []
  },
  mounted() {
    this.clearFilter();
  },
  computed: {
    dateRangeBegin() {
      if (this.provider_result.searchByDateRange) {
        return this.provider_result.searchByDateRange[0]
      } else {
        return null
      }
    },
    dateRangeEnd() {
      if (this.provider_result.searchByDateRange) {
        return this.provider_result.searchByDateRange[1]
      } else {
        return null
      }
    },
    advancedLabel() {
      let label = ''
      let isTDStaff = this.$store.getters['auth_user/isTDStaff']
      let resultsCountOnetime = this.$store.getters['provider_result/resultsCountOnetime']
      let resultsCountMonitoring = this.$store.getters['provider_result/resultsCountMonitoring']
      let totalResult = resultsCountOnetime + resultsCountMonitoring
      let totalResultOnServer = this.provider_result.resultsOneTimeTotalCountOnServer + this.provider_result.resultsMonitoringTotalCountOnServer
      // Some results are duplicates
      if (totalResultOnServer > totalResult) {
        label += 'PARTIAL results '
        label += '(' + this.provider_result.searchByCountLimit + ' of ' + formatNumber(totalResultOnServer) + ') '
        // label += '(Results Max = ' + this.provider_result.countLimit + '): '
      } else {
        label = 'Results for: '
      }
      if (this.dateRangeBegin) {
        label += formatDate(this.dateRangeBegin, 'MMM D, YYYY') + ' - ' + formatDate(this.dateRangeEnd, 'MMM D, YYYY')
      }
      if (this.provider_result.searchByFirstName && this.provider_result.searchByFirstName.length > 0) label += ' | ' + this.provider_result.searchByFirstName
      if (this.provider_result.searchByLastName && this.provider_result.searchByLastName.length > 0) label += ' | ' + this.provider_result.searchByLastName
      if (this.provider_result.searchByOrganizationName && this.provider_result.searchByOrganizationName.length > 0) label += ' | ' + this.provider_result.searchByOrganizationName
      if (this.provider_result.searchByReferenceNumber && this.provider_result.searchByReferenceNumber.length > 0) label += ' | ' + this.provider_result.searchByReferenceNumber
      let showIt = true
      if (showIt && isTDStaff && false) {
        // if (totalResultOnServer > totalResult) {
        label += ' | (Total ' + formatNumber(totalResult) + ' of ' + formatNumber(totalResultOnServer)
        label += ' results)'
        // }
        // if (this.provider_result.resultsOneTimeTotalCountOnServer > resultsCountOnetime) {
        label += ' | [O ' + formatNumber(resultsCountOnetime) + ' of ' + formatNumber(this.provider_result.resultsOneTimeTotalCountOnServer)
        label += ' results]'
        // }
        // if (this.provider_result.resultsMonitoringTotalCountOnServer > resultsCountMonitoring) {
        label += ' | {M ' + formatNumber(resultsCountMonitoring) + ' of ' + formatNumber(this.provider_result.resultsMonitoringTotalCountOnServer)
        label += ' results}'
        // }
      }
      return label
    },
    ...mapGetters('auth_user', ['hasAdmin']),
    ...mapGetters('group', ['sortedGroups']),
    ...mapGetters('layout', ['intuitiveUI', 'isFADV']),
    ...mapGetters('provider_result', ['searchByFilters']),
    ...mapState({
      userState: state => state.user
    }),
    ...mapState(['provider_result', 'user_profile'])
  },
  methods: {
    clearFilter() {
      this.clearKey += 1
      this.dateRangeOption = null
      this.$store.dispatch('provider_result/clearFilters', {}).then(() => {
        this.$store.dispatch('provider_result/setSearchByDateRange', { days: 1 })
      })
    },
    fetchResults() {
      let searchOptions = {
        includeMonitoring: this.includeMonitoring,
        includeOnetime: this.includeOnetime
      }
      this.$store.dispatch('provider_result/fetchIt', { options: searchOptions }).then(() => {})
    },
    formReset() {
      this.clearFilter()
    },
    getFilteredGroups(text) {
      this.filteredTags = this.$store.getters['group/sortedGroups'].filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    dateRangeByOption() {},
    dateRangeLast30Days() {
      let today = new Date()
      today.setDate(today.getDate() + 1)
      let startDate = new Date(today)
      startDate.setDate(today.getDate() - 30)
      this.pprovider_result.searchByDdateRange = [startDate, today]
    },
    dateRangeLast7Days() {
      let today = new Date()
      today.setDate(today.getDate() + 1)
      let startDate = new Date(today)
      startDate.setDate(today.getDate() - 7)
      this.provider_result.searchByDateRange = [startDate, today]
    },
    dateRangePreviousMonth() {
      let today = new Date()
      today.setDate(today.getDate() + 1)
      let startDate = new Date(today)
      startDate.setMonth(today.getMonth() - 1)
      startDate.setDate(1)
      let endDate = new Date(startDate)
      endDate.setMonth(startDate.getMonth() + 1)
      endDate.setDate(endDate.getDate() - 1)
      this.provider_result.searchByDateRange = [startDate, endDate]
    },
    dateRangePreviousWeek() {
      let dayInWeek = new Date()
      dayInWeek.setDate(dayInWeek.getDate() - 7)
      let startDate = new Date(dayInWeek.setDate(dayInWeek.getDate() - dayInWeek.getDay()))
      let endDate = new Date(dayInWeek.setDate(dayInWeek.getDate() - dayInWeek.getDay() + 6))
      this.provider_result.searchByDateRange = [startDate, endDate]
    },
    dateRangeNone() {
      this.provider_result.searchByDateRange = []
    },
    dateRangeMinDate() {
      
      let date = new Date()
      date.setFullYear('2014')
      date.setMonth('0')
      
      return date
    },
    dateRangeMaxDate() {
      let today = new Date()
      today.setDate(today.getDate() + 1)
      return today
    },
    onGetResults() {
      this.fetchResults({})
    },
    onSubmitSearchOptions() {
      this.onGetResults()
      this.$store.dispatch('user_profile/save', {})
    },
    onCollapse(value) {
      this.advancedOpen = value
      localStorage.setItem('advanced-open', this.advancedOpen)
    }
  }
}
</script>

<style scoped>
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #3498db;
  border-color: #3498db;
}
/* buefy BEGIN */
.button {
  white-space: normal;
  height: auto;
}
/* buefy END */

.btn-primary {
  border-color: #3498db;
}

.btn-primary:hover {
  background-color: #369ade;
  border-color: #3498db;
}

.btn-primary:active {
  background-color: #3498db;
  border-color: #3498db;
}

.btn-primary:visited {
  background-color: #3498db;
  border-color: #3498db;
}
</style>
