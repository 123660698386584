<template>
  <section v-if="resultsIsEmpty && !isBusy && !isBatch">
    <b-message type="is-info">
      No results to display. To update results, specify search criteria and click Search above.
    </b-message>
  </section>
  <section v-else>
    <div v-if="showFilter" class="buttons">
      <button v-if="!intuitiveUI" class="button field is-default" style="height:2.57em" @click="showSettings = !showSettings">
        <b-icon icon="settings-outline"></b-icon>
      </button>
      <span v-if="intuitiveUI || resultsCountVerifiedImpairment > 0" class="button is-typhd-critical is-outlined">
        <div class="card-content" style="padding:2px">
          <p class="heading">{{ user_profile.labels.VerifiedImpairment }} ({{ resultsCountVerifiedImpairment }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isVerifiedImpairmentLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-critical">{{
              provider_result.isVerifiedImpairmentLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-if="intuitiveUI || resultsCountReviewRequired > 0" class="button is-typhd-warning is-outlined">
        <div class="card-content" style="padding:2px">
          <p class="heading">{{ user_profile.labels.ReviewRequired }} ({{ resultsCountReviewRequired }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isReviewRequiredLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-warning">{{
              provider_result.isReviewRequiredLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-if="!isIqvia && resultsCountNonAdverse > 0" class="button is-typhd-grey is-outlined">
        <div class="card-content" style="padding:2.5px">
          <p class="heading">{{ user_profile.labels.NonAdverse }} ({{ resultsCountNonAdverse }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isNonAdverseLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-grey">{{
              provider_result.isNonAdverseLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-if="!isIqvia && resultsCountPending > 0" class="button is-typhd-grey is-outlined">
        <div class="card-content" style="padding:2.5px">
          <p class="heading">{{ user_profile.labels.Pending }} ({{ resultsCountPending }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isPendingLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-grey">{{
              provider_result.isPendingLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span v-if="!isIqvia && rosterCountNoResultsFound > 0" class="button is-typhd-grey is-outlined">
        <div class="card-content" style="padding:2.5px">
          <p class="heading">{{ user_profile.labels.NoResultsFound }}({{ rosterCountNoResultsFound }})</p>
          <div class="button">
            <b-switch v-model="provider_result.isNoResultsFoundLabel" class="has-text-grey has-text-weight-normal" true-value="Included" false-value="Not-included" type="is-typhd-grey">{{
              provider_result.isNoResultsFoundLabel
            }}</b-switch>
          </div>
        </div>
      </span>
      <span class="button is-typhd-info is-outlined">
        <div class="card-content" style="padding:6px">
          <p class="heading">{{ labelUnRead }} ({{ resultsCountUnRead }})</p>
          <b-select v-model="provider_result.isRead">
            <option value>Both</option>
            <option value="ack">{{ labelRead }}</option>
            <option value="unack">{{ labelUnRead }}</option>
          </b-select>
        </div>
      </span>
      <span v-if="profileReady" v-show="showSettings" class="buttons">
        <span class="button is-outlined">
          <span class="button is-outlined">
            <div class="card-content" style="padding:7px">
              <label class="label">Columns</label>
              <b-field grouped group-multiline>
                <div v-for="(column, index) in resultsColumns" :key="index" class="control">
                  <b-checkbox v-model="column.display">
                    {{ column.label }}
                  </b-checkbox>
                </div>
              </b-field>
            </div>
          </span>
          <span class="button is-outlined">
            <div class="card-content" style="padding-top:10px;padding-bottom:14px">
              <label class="label">Other</label>
              <b-field v-if="resultsOptions" grouped group-multiline>
                <div>
                  <b-checkbox v-model="resultsOptions.quickSearch">
                    Quick Filter
                  </b-checkbox>
                </div>
              </b-field>
            </div>
          </span>
          <button class="button is-primary" @click="SaveSettings()">Remember</button>
          <button class="button is-default" @click="ResetSettings()">Reset</button>
        </span>
      </span>
    </div>
    <b-table
      :data="filteredResults"
      :striped="true"
      :checked-rows.sync="checkedResults"
      :checkable="true"
      :current-page.sync="currentPage"
      :loading="isBusy"
      narrowed
      :paginated="true"
      :pagination-position="paginationPosition"
      :pagination-simple="false"
      :per-page="perPage"
      :default-sort-direction="defaultSortDirection"
      default-sort="id"
      :detailed="true"
      detail-key="id"
      :opened-detailed="defaultOpenedDetails"
    >
      <template v-slot:top-left>
        <b-field grouped group-multiline>
          <button class="button field is-default" style="height:2.57em" @click="onRefesh()">
            <b-icon icon="refresh"></b-icon>
          </button>
          <b-select v-if="checkedResults.length > 0" id="actions_select" name="actionsSelect" v-model="selectedAction" @input="onMarkedAll()" placeholder="With Selected" class="field is-default">
            <option value="ack">{{ labelRead }}</option>
            <option value="unack">{{ labelUnRead }}</option>
          </b-select>
          <b-select id="actions_export" name="actionsExport" :disabled="exportInprocess" v-model="exportFormat" @input="exportToFile()" placeholder="Export" class="field" icon="download">
            <option value="csv">.csv</option>
            <option value="biff2">.xls</option>
            <option value="xlsx">.xlsx</option>
          </b-select>
          <b-field v-if="resultsOptions.quickSearch && resultsColumns && resultsColumns.length > 0" grouped group-multiline>
            <b-input v-if="resultsColumns[colId].display" v-model.trim="provider_result.filterId" placeholder="Result ID" />
            <b-select v-if="resultsColumns[colCustId].display && hasMoreThanOneCustomer" name="companySelect" v-model="provider_result.filterCustomerId" placeholder="Customer ID">
              <option value=""></option>
              <option v-for="option in listOfCustomerIdsSorted" :value="option" :key="option">
                {{ option }}
              </option>
            </b-select>
            <b-input v-if="resultsColumns[colSubId].display" v-model.trim="provider_result.filterSubjectId" placeholder="Provider ID" />
            <b-select v-if="resultsColumns[colGroup].display" id="group_select" name="groupSelect" v-model="provider_result.filterGroup" placeholder="Group">
              <option value=""></option>
              <option v-for="g in sortedGroups" :value="g.name" :key="g.id">{{ g.name }}</option>
            </b-select>
            <b-input v-if="resultsColumns[colName].display" v-model.trim="provider_result.filterName" placeholder="Name" />
            <b-input v-if="resultsColumns[colRef].display" v-model.trim="provider_result.filterReference" placeholder="Reference" />
            <button type="reset" class="button is-default" style="height:2.57em" id="next_button" @click="formReset()">Clear</button>
          </b-field>
        </b-field>
      </template>
      <template v-slot:empty>
        <section class="section">
          <div class="content has-text-grey has-text-centered">
            <p v-if="isBusy">Getting results.</p>
            <p v-else>No results</p>
          </div>
        </section>
      </template>
      <template v-slot="props">
        <b-table-column :visible="resultsColumns[colId].display" :field="resultsColumns[colId].field" :label="resultsColumns[colId].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <span>{{ props.row.alert_id }}</span>
          <div v-if="isTDStaff && props.row.search_result">Record ID: {{ props.row.search_result.record_id }}</div>
        </b-table-column>
        <b-table-column :visible="resultsColumns[colCustId].display" :field="resultsColumns[colCustId].field" :label="resultsColumns[colCustId].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <span>{{ props.row.customer_id }} </span>
        </b-table-column>
        <b-table-column :visible="resultsColumns[colCrAt].display" :field="resultsColumns[colCrAt].field" :label="resultsColumns[colCrAt].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>{{
          props.row.created_at | shortDate
        }}</b-table-column>
        <b-table-column :visible="resultsColumns[colSubId].display" :field="resultsColumns[colSubId].field" :label="resultsColumns[colSubId].label" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <span v-if="props.row.isMonitoring">M{{ props.row.subject_id }}</span>
          <span v-else>{{ props.row.subject_id }}</span>
        </b-table-column>
        <b-table-column
          :visible="resultsColumns[colGroup].display && showMonitoring"
          :field="resultsColumns[colGroup].field"
          :label="resultsColumns[colGroup].label"
          :class="{ 'has-text-weight-bold': !props.row.ack }"
          sortable
          >{{ props.row.group }}
        </b-table-column>
        <b-table-column :visible="resultsColumns[colName].display" :field="resultsColumns[colName].field" :label="resultsColumns[colName].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <router-link :to="{ name: 'provider-view', query: { id: props.row.subject_id, monitoring: props.row.isMonitoring } }">{{ props.row | fullName }}</router-link>
        </b-table-column>
        <b-table-column :visible="resultsColumns[colRef].display && showReference" :field="resultsColumns[colRef].field" :label="resultsColumns[colRef].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable
          >{{ props.row.reference }}
        </b-table-column>
        <b-table-column :visible="resultsColumns[colSource].display && showSource" :field="resultsColumns[colSource].field" :label="resultsColumns[colSource].label" :class="{ 'has-text-weight-bold': !props.row.ack }">
          <span>{{ props.row.source_name }}</span>
        </b-table-column>
        <b-table-column
          :visible="resultsColumns[colLevel].display && showLevel"
          :field="resultsColumns[colLevel].field"
          :label="resultsColumns[colLevel].label"
          :class="{ 'has-text-weight-bold': !props.row.ack }"
          sortable
        >
          <span :style="{ color: resultColor(props.row.alertLevel) }">
            <div v-if="filteredLevels.includes(props.row.alertLevel)">{{ props.row.alertLevel | alertLevelWords(user_profile.labels) }}</div>
          </span>
        </b-table-column>
        <b-table-column :visible="resultsColumns[colDetail].display" :field="resultsColumns[colDetail].field" :label="resultsColumns[colDetail].label" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable
          >{{ props.row.detail }}
        </b-table-column>
        <b-table-column :visible="showStatus" field="field" label="Status" :class="{ 'has-text-weight-bold': !props.row.ack }" sortable>
          <div v-if="props.row.completed_at">COMPLETED</div>
        </b-table-column>
        <b-table-column :visible="resultsColumns[colAction].display && showActions" :field="resultsColumns[colAction].field" :label="resultsColumns[colAction].label">
          <span class="buttons" v-if="props.row.verified != 'PENDING'">
            <b-button v-if="props.row.ack" class="is-default is-rounded" @click="onMarkAsUnAck(props.row)">{{ labelBtnUnRead }}</b-button>
            <b-button v-if="!props.row.ack" class="is-primary is-rounded" @click="onMarkAsAck(props.row)">{{ labelBtnRead }}</b-button>
          </span>
        </b-table-column>
      </template>
      <template v-slot:detail="props">
        <AlertDetail :isMonitoring="props.row.isMonitoring" :alertId="props.row.alert_id" :licenseResultId="props.row.license_result_id" :subjectId="props.row.subject_id" />
      </template>
    </b-table>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { actionResultColor, actionLevelWords, exportResults, resultFirstName, resultMiddleName, resultLastName } from '@/helpers/provider_helpers'
import { getProductOption } from '@/helpers/product_helpers'
import AlertDetail from '@/components/bryce/AlertDetail.vue'

export default {
  props: {
    isBatch: {
      type: Boolean,
      default: false
    },
    overrideIntuitive: {
      type: Boolean,
      default: false
    },
    showActions: {
      type: Boolean,
      default: true
    },
    showFilter: {
      type: Boolean,
      default: true
    },
    showMonitoring: {
      type: Boolean,
      default: false
    },
    showOnetime: {
      type: Boolean,
      default: false
    },
    showLevel: {
      type: Boolean,
      default: true
    },
    showReference: {
      type: Boolean,
      default: true
    },
    showStatus: {
      type: Boolean,
      default: false
    },
    showSource: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      allSelected: null,
      countPerQuery: 500,
      exportInprocess: false,
      selectedAction: null,
      exportFormat: null,

      colId: 0,
      colCustId: 1,
      colCrAt: 2,
      colSubId: 3,
      colGroup: 4,
      colName: 5,
      colRef: 6,
      colSource: 7,
      colLevel: 8,
      colDetail: 9,
      colAction: 10,
      defaultOpenedDetails: [],
      defaultSortDirection: 'desc',
      paginationPosition: 'both',
      currentPage: 1,
      perPage: 125,
      checkedResults: [],
      showSettings: false,

      filteredTags: this.$store.state.group.groups,
      clearKey: 0
    }
  },
  components: {
    AlertDetail
  },
  created() {
    this.$store.dispatch('group/fetchGroups', {})
  },
  computed: {
    ...mapGetters('auth_user', ['isTDStaff']),
    ...mapGetters('group', ['sortedGroups']),
    ...mapGetters('provider_result', [
      'filteredLevels',
      'filteredResults',
      'resultsCountUnRead',
      'resultsCountRead',
      'rosterCountNoResultsFound',
      'resultsCountVerifiedImpairment',
      'resultsCountNonAdverse',
      'resultsCountMonitoring',
      'resultsCountOnetime',
      'resultsCountPending',
      'resultsCountReviewRequired',
      'resultsLevelLabel',
      'subjectsCountTotal'
    ]),
    ...mapGetters('layout', ['intuitiveUI', 'isIqvia']),
    ...mapGetters('provider_result', ['isBusy', 'hasMoreThanOneCustomer', 'listOfCustomerIdsSorted', 'resultsIsEmpty', 'isBusy']),
    ...mapGetters('user_profile', ['labelBtnRead', 'labelBtnUnRead', 'labelRead', 'labelUnRead', 'profileReady', 'resultsColumns', 'resultsOptions']),
    ...mapState(['batch', 'group', 'provider_result', 'user_profile']),
    ...mapState(['td_constant'])
  },
  methods: {
    ResetSettings() {
      this.$store.dispatch('user_profile/resetSettings', {})
    },
    SaveSettings() {
      this.$store.dispatch('user_profile/save', {})
    },
    formReset() {
      this.$store.dispatch('provider_result/clearLocalFilters', {})
    },
    getFilteredGroups(text) {
      this.filteredTags = this.$store.state.group.groups.filter(option => {
        return (
          option.name
            .toString()
            .toLowerCase()
            .indexOf(text.toLowerCase()) >= 0
        )
      })
    },
    resultColor(alertLevel) {
      return actionResultColor({ verified: alertLevel })
    },
    resultCount(alertLevel, actionResult) {
      if (actionResult.alertLevelCounts && actionResult.alertLevelCounts[alertLevel] > 1) return ' (' + actionResult.alertLevelCounts[alertLevel] + ')'
    },
    exportToFile() {
      if (this.exportFormat != '') {
        this.exportInprocess = true
        exportResults(this.exportFormat, this.filteredLevels, this.$store.getters['provider_result/filteredResults'], null, this.$store.state.layout, this.$store.state.user_profile.labels)
        this.exportInprocess = false
      }
    },
    onMarkedAll() {
      if (!this.selectedAction || this.selectedAction == '') return
      for (let i in this.checkedResults) {
        let subject = this.checkedResults[i]
        let foundItems = this.$store.state.provider_result.results.filter(result => result.subject_id == subject.id && result.isMonitoring == subject.isMonitoring)
        for (let rIndex in foundItems) {
          if (this.selectedAction == 'ack') this.onMarkAsAck(foundItems[rIndex])
          else if (this.selectedAction == 'unack') this.onMarkAsUnAck(foundItems[rIndex])
        }
      }
      this.selectedAction = ''
    },
    onMarkAsAck(result) {
      this.$store.dispatch('action_result/resultMarkAsRead', {
        alertId: result.alert_id,
        licenseResultId: result.license_result_id,
        searchResultId: result.search_result_id,
        subjectId: result.subject_id,
        isMonitoring: result.isMonitoring
      })
    },
    onMarkAsUnAck(result) {
      this.$store.dispatch('action_result/resultMarkAsUnRead', {
        alertId: result.alert_id,
        licenseResultId: result.license_result_id,
        searchResultId: result.search_result_id,
        subjectId: result.subject_id,
        isMonitoring: result.isMonitoring
      })
    },
    onRefesh: function() {
      this.$emit('refresh')
    },
    resume(row) {
      this.$store.dispatch('provider_result/resume', { subjectId: +row.id })
    },
    pause(row) {
      this.$store.dispatch('provider_result/pause', { subjectId: +row.id })
    }
  }
}
</script>

<style scoped>
.btn-primary,
.btn-primary:active,
.btn-primary:focus,
.btn-primary:hover {
  background-color: #3498db;
  border-color: #3498db;
}
/* buefy BEGIN */
.button {
  white-space: normal;
  height: auto;
}
/* buefy END */

.btn-primary {
  border-color: #3498db;
}

.btn-primary:hover {
  background-color: #369ade;
  border-color: #3498db;
}

.btn-primary:active {
  background-color: #3498db;
  border-color: #3498db;
}

.btn-primary:visited {
  background-color: #3498db;
  border-color: #3498db;
}

/* switcher-ion BEGIN */
.switcher-ion {
  width: 104px; /* Add to this */
}
.switcher-ion-inner {
  width: 200%;
  margin-left: -100%;

  -moz-transition: margin 0.3s ease-in 0s;
  -webkit-transition: margin 0.3s ease-in 0s;
  -o-transition: margin 0.3s ease-in 0s;
  transition: margin 0.3s ease-in 0s;
}
.switcher-ion-inner:before,
.switcher-ion-inner:after {
  float: left;
  width: 50%;
  height: 26px;
  padding: 0;
  line-height: 26px;
  font-size: 14px;
  color: white;
  font-weight: normal;
}
.switcher-ion-inner-warning:before {
  content: 'Included';
  color: #f39c12; /* Text color of On */
}
.switcher-ion-inner-critical:before {
  content: 'Included';
  color: #e74c3c; /* Text color of On */
}
.switcher-ion-inner-warning:after {
  content: 'Not Inc.';
}
.switcher-ion-inner-critical:after {
  content: 'Not Inc.';
}
.switcher-ion-switch {
  width: 30px;
  height: 30px;
  margin: 0px;
  background: #808080; /* button color of Off */
  border: 2px solid #fff;
  border-radius: 30px;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 74px; /* Add to this */

  -moz-transition: all 0.3s ease-in 0s;
  -webkit-transition: all 0.3s ease-in 0s;
  -o-transition: all 0.3s ease-in 0s;
  transition: all 0.3s ease-in 0s;
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-inner {
  margin-left: 0;
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-switch {
  right: 0px;
  background-color: #3498db; /* button color of On 3498db */
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-switch-warning {
  right: 0px;
  background-color: #f39c12; /* button color of On 3498db */
}
.switcher-ion-checkbox:checked + .switcher-ion-label .switcher-ion-switch-critical {
  right: 0px;
  background-color: #e74c3c; /* button color of On 3498db */
}
/* switcher-ion END */
</style>
