<template>
  <div>
    <TdPanel panelId="product_list" :panelTitle="panelLabel()">
      <section class="section">
        <b-table :striped="true" :data="nonLicenseOrders" narrowed :opened-detailed="defaultOpenedDetails" detail-key="id">
          <template v-slot:empty> </template>
          <template v-slot="props">
            <b-table-column label="Product">{{ props.row.name }} </b-table-column>
            <b-table-column label="Detail">
              <span>{{ props.row.detail }}</span>
              <span v-if="props.row.verify && props.row.in_verification > 0">
                <span style="font-weight: bold;"> - {{ props.row.in_verification }} in Verification</span>
              </span>
            </b-table-column>
            <b-table-column label="Status">
              {{ props.row.status }}
            </b-table-column>
            <b-table-column label="Last Checked">
              {{ props.row.last_checked_at | longDate }}
            </b-table-column>
            <b-table-column label="Verify"> {{ props.row.verify | yesNo }} </b-table-column>
          </template>
        </b-table>
        <p></p>
      </section>
      <ProductEnrollHistory :subjectId="subjectId" />
    </TdPanel>
    <TdPanel panelId="license_list" panelTitle="Licenses">
      <section class="section">
        <LicensesForm :subjectId="+subjectId" :subjectType="subjectType" :monitoring="true" :detail="true" />
      </section>
    </TdPanel>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import LicensesForm from '@/components/bryce/LicensesForm.vue'
import ProductEnrollHistory from '@/components/bryce/ProductEnrollHistory.vue'

export default {
  components: {
    LicensesForm,
    ProductEnrollHistory
  },
  props: {
    subjectId: {
      required: true
    },
    subjectType: {
      default: '',
      type: String,
      required: true
    }
  },
  data() {
    return {
      defaultOpenedDetails: [],
      historyResults: []
    }
  },
  computed: {
    ...mapGetters('layout', ['intuitiveUI']),
    ...mapGetters('product_order', ['nonLicenseOrders']),
    ...mapState(['product_order'])
  },
  created() {
    this.$store.dispatch('product_order/fetchProductOrders', { subjectId: this.subjectId })
    // this.$store
    //   .dispatch('monitoring/fetchProductEnrollHistory', {
    //     searchOptions: {
    //       monitoring_subject_id: +this.subjectId
    //     }
    //   })
    //   .then(historyResults => {
    //     this.historyResults = historyResults
    //   })
  },
  methods: {
    panelLabel() {
      if (this.intuitiveUI) {
        return 'Monitored Products'
      } else return 'Products'
    }
  }
}
</script>

<style scoped></style>
