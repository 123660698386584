<template>
  <section class="">
    <!-- <section class="box">
      <form class="" @submit="onSubmitSearchOptions">
        <div class="field is-grouped">
          <b-field label="Is Active">
            <b-select v-model="status">
              <option value selected="selected">ALL</option>
              <option value="YES">Yes</option>
              <option value="NO">No</option>
            </b-select>
          </b-field>
          <b-field label="Customer Name">
            <b-input v-model.trim="customerName"> </b-input>
          </b-field>
          <label class="label">&nbsp;</label>
          <div class="buttons">
            <button type="submit" class="button is-primary" id="next_button" @click="onSubmitSearchOptions">Search</button>
            <button type="reset" class="button is-default" id="next_button" @click="formReset()">Clear</button>
          </div>
        </div>
      </form>
    </section> -->
    <TdPanel panelId="search_results" panelTitle="Customers">
      <div class="panel-body">
        <b-table
          :striped="true"
          :data="customer.customers"
          :current-page.sync="currentPage"
          narrowed
          :paginated="true"
          :pagination-simple="false"
          :per-page="perPage"
          :default-sort-direction="defaultSortDirection"
          default-sort="id"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="id"
        >
          <template v-slot:empty>
            No customers
          </template>
          <template v-slot="props">
            <b-table-column field="id" label="ID" sortable numeric>
              <router-link :to="{ name: 'customer-view', params: { id: props.row.id } }">{{ props.row.id }}</router-link>
            </b-table-column>
            <b-table-column field="reseller_id" label="Reseller ID" sortable numeric>
              {{ props.row.reseller_id }}
            </b-table-column>
            <b-table-column field="name" label="Name" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column field="customer_type" label="Type" sortable>
              {{ props.row.customer_type }}
            </b-table-column>
            <b-table-column field="is_active" label="Active" sortable>
              {{ props.row.is_active | yesNo }}
            </b-table-column>
          </template>
          <template v-slot:detail="props">
            <CustomerDetail :customerId="props.row.id" :customer="props.row" />
          </template>
        </b-table>
      </div>
    </TdPanel>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import CustomerDetail from '@/components/CustomerDetail.vue'

export default {
  props: {},
  data() {
    return {
      customerName: null,
      status: null,

      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 150,
      perPageDownloadCount: 400,
      defaultOpenedDetails: [],
      pageNum: 1,
      pageCount: 250
    }
  },
  computed: {
    ...mapState(['customer'])
  },
  components: {
    CustomerDetail
  },
  created() {
    if (this.$store.getters['auth_user/hasAdmin'])
      this.$store.dispatch('customer/fetchCustomers', {
        searchOptions: {
          page: 1,
          page_count: this.perPageDownloadCount
        }
      })
  },
  methods: {
    formReset() {
      this.status = null
      this.customerName = null
      this.$store.dispatch('customer/fetchCustomers', {
        searchOptions: {
          page: 1,
          page_count: this.perPageDownloadCount
        }
      })
    },
    onSubmitSearchOptions(e) {
      e.preventDefault()
      this.$store.dispatch('customer/fetchCustomers', {
        searchOptions: {
          customer_name: this.customerName,
          page: 1,
          page_count: this.perPageDownloadCount
        }
      })
    }
  }
}
</script>

<style scoped></style>
