<template>
  <div>
    <form @submit.prevent="submit">
      <b-field>
        <label class="label">Search/Order ID</label>
        <TdInput class="inputfield" type="id" :hasIconLeft="false" v-model.trim="credentials.id" maxlength="100" pattern="[-a-zA-Z0-9]"></TdInput>
      </b-field>
      <b-field>
        <label class="label">Primary First Name</label>
        <TdInput class="inputfield" type="text" :hasIconLeft="false" v-model.trim="credentials.first" maxlength="100"></TdInput>
      </b-field>
      <b-field>
        <label class="label">Primary Last Name</label>
        <TdInput class="inputfield" type="text" :hasIconLeft="false" v-model.trim="credentials.last" maxlength="100"></TdInput>
      </b-field>
      <b-field>
        <label class="label">Date of Birth</label>
        <TdInput class="inputfield" type="date" :hasIconLeft="false" v-model.trim="credentials.dob"></TdInput>
      </b-field>
      <b-field v-if="showValidationMessage" type="is-danger">
        <b-message title="Validation Error" type="is-danger" aria-close-label="Close message">
          Please fill in at least 3 out of the 4 fields.
        </b-message>
      </b-field>
      <b-field v-if="showAlphaNumericMessage" type="is-danger">
        <b-message title="Validation Error" type="is-danger" aria-close-label="Close message">
          Please only enter alpha numeric and hyphen for Search/Order ID
        </b-message>
      </b-field>
      <b-field v-if="showDateMessage" type="is-danger">
        <b-message title="Validation Error" type="is-danger" aria-close-label="Close message">
          Date should either be left empty or correct length (8 numeric characters plus two separation (-/) characters)
        </b-message>
      </b-field>
      <b-field v-if="showSecondDateMessage" type="is-danger">
        <b-message title="Validation Error" type="is-danger" aria-close-label="Close message">
          Date cannot be a date in the future
        </b-message>
      </b-field>
      <TdSubmit label="Get Results" class="red-button" />
    </form>
    <div v-if="showWaitMessage" class="wait-message">
      Please wait while we fetch your file...
    </div>
    <div v-if="showUrlErrorMessage" class="url-error-message">
      Error: Order number is missing from the URL.
    </div>
    <div v-if="errorMessage" class="api-error-message">
      Failed: {{ errorMessage }}
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FileSaver from 'file-saver';
import apiClient from '@/services/shared/AxiosService.js';

export default {
  props: {
    referenceID: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      credentials: {
        id: '',
        first: '',
        last: '',
        dob: ''
      },
      showSecondDateMessage: false,
      showDateMessage: false,
      showAlphaNumericMessage: false,
      showValidationMessage: false,
      showWaitMessage: false,
      showUrlErrorMessage: false,
      errorMessage: null
    };
  },
  computed: {
  ...mapState('layout', ['brandName'])
  },
  methods: {
    async submit() {
      // Clear previous error/warning messages
      this.showSecondDateMessage = false;
      this.showDateMessage = false;
      this.showAlphaNumericMessage = false;
      this.showValidationMessage = false;
      this.showWaitMessage = false;
      this.showUrlErrorMessage = false;
      this.errorMessage = null;

      const num_creds =
        (this.credentials.id.length > 0) +
        (this.credentials.first.length > 0) +
        (this.credentials.last.length > 0) +
        (this.credentials.dob.length > 0);

      if (this.credentials.dob.length !== 10 && this.credentials.dob.length !== 0) {
        this.showDateMessage = true;
      } else {
        this.showDateMessage = false;
      }

      const dob = new Date(this.credentials.dob);
      const today = new Date();
      if (dob > today) {
        this.showSecondDateMessage = true;
      } else {
        this.showSecondDateMessage = false;
      }

      for (let i = 0; i < this.credentials.id.length; i++) {
        if (this.credentials.id[i].match(/[^a-zA-Z0-9-]/)) {
          this.showAlphaNumericMessage = true;
          break;
        } else {
          this.showAlphaNumericMessage = false;
        }
      }

      if (num_creds >= 3) {
        this.showValidationMessage = false;

        if (this.referenceID) {
          this.showWaitMessage = true;
          this.showUrlErrorMessage = false;

          const requestData = {
            orderId: this.credentials.id,
            firstName: this.credentials.first,
            lastName: this.credentials.last,
            dob: this.credentials.dob
          };

          try {
            const response = await apiClient({
              method: 'post',
              url: `/cdaas/${this.referenceID}`,
              data: requestData,
              responseType: 'arraybuffer' // Ensure the response is treated as binary data
            });

            const contentType = response.headers['content-type'];

            if (contentType.includes('application/json')) {
              const text = new TextDecoder().decode(response.data);
              const jsonResponse = JSON.parse(text);
              if (jsonResponse.error) {
                this.errorMessage = jsonResponse.error;
              } else {
                const blob = new Blob([JSON.stringify(jsonResponse, null, 2)], { type: 'application/json' });
                const url = window.URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = `${this.referenceID}.json`;
                document.body.appendChild(a);
                a.click();
                window.URL.revokeObjectURL(url);
                document.body.removeChild(a);
              }
            } else {
               // Otherwise, proceed with saving the file
              const contentType = response.headers['content-type'];

              const blob = new Blob([response.data], { type: contentType });

              let filename = this.referenceID;
              const contentDisposition = response.headers['content-disposition'];

              if (contentDisposition) {
                const filenameMatch = /filename="([^"]*)"/i.exec(contentDisposition);
                if (filenameMatch && filenameMatch.length > 1) {
                  filename = filenameMatch[1];
                } else {
                  const typeMatch = contentType.match(/[^/]+$/);
                  if (typeMatch && typeMatch.length > 0) {
                    filename += "." + typeMatch[0];
                  } else {
                    filename += ".bin";
                  }
                }
              } else {
                const typeMatch = contentType.match(/[^/]+$/);
                if (typeMatch && typeMatch.length > 0) {
                  filename += "." + typeMatch[0];
                } else {
                  filename += ".bin";
                }
              }

              FileSaver.saveAs(blob, filename);
            }
          } catch (error) {
            console.error('API Error:', error);
            this.showUrlErrorMessage = false;

            // Force update errorMessage to trigger reactivity
            this.$nextTick(() => {
              this.errorMessage = this.extractErrorMessage(error);
            });
          } finally {
            // Hide the waiting message after the API call is complete
            this.showWaitMessage = false;
          }
        } else {
          this.showUrlErrorMessage = true;
        }
      } else {
        this.showValidationMessage = true;
      }
    },

    extractErrorMessage(error) {
      if (error.response && error.response.data) {
        try {
          // Attempt to parse the response data as JSON
          const text = new TextDecoder().decode(error.response.data);
          const jsonError = JSON.parse(text);
          if (jsonError && jsonError.error) {
            return jsonError.error; // Return the error message from the JSON response
          }
        } catch (jsonParseError) {
          // If parsing fails, return the raw response data
          return error.response.data;
        }
      }
      return 'An error occurred.';
    }
  }
};
</script>

<style scoped>.wait-message {
  margin-top: 10px;
  font-style: italic;
}.url-error-message {
  margin-top: 10px;
  color: #9e1b32;
}.api-error-message {
  margin-top: 10px;
  color: #9e1b32;
}.red-button {
  background-color: #9e1b32!important;
  color: #e7e7e7e7!important;
}.label {
  font-family: 'Open Sans';
  width: 25%
}.inputfield {
  align-items: right!important;
  width: 100%!important;
}
</style>