<template>
  <div>
    <!-- {{ users }}|{{customerId}} -->
    <TdPanel panelId="users" panelTitle="Users">
      <b-table :striped="true" :data="users" :current-page.sync="currentPage" narrowed :paginated="true" :pagination-simple="false" :per-page="perPage" :default-sort-direction="defaultSortDirection" default-sort="name">
        <template v-slot:empty>
          No users
        </template>
        <template v-slot="props">
          <b-table-column field="id" label="User ID" sortable numeric>
            {{ props.row.id }}
          </b-table-column>
          <b-table-column field="id" label="CUID" sortable numeric>
            {{ props.row.customer_user_id }}
          </b-table-column>
          <b-table-column field="username" label="Name" sortable>
            {{ props.row.username }} <router-link :to="{ name: 'proxy-login', params: { id: props.row.customer_user_id } }"> (Proxy Login)</router-link>
          </b-table-column>
          <b-table-column field="role" label="Role" sortable>
            {{ props.row.role }}
          </b-table-column>
          <b-table-column field="is_active" label="Active" sortable>
            {{ props.row.is_active | yesNo }}
          </b-table-column>
        </template>
      </b-table>
    </TdPanel>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    customerId: {
      required: true
    }
  },
  data() {
    return {
      users: [],

      defaultOpenedDetails: [],
      defaultSortDirection: 'asc',
      currentPage: 1,
      perPage: 25
    }
  },
  computed: {
    ...mapGetters('auth_user', ['isTDStaff'])
  },
  components: {},
  created() {
    this.$store.dispatch('customer/fetchCustomerUsers', { customerId: this.customerId }).then(users => {
      this.users = users
    })
  },
  methods: {}
}
</script>

<style scoped></style>
