<template>
  <div>
    <section v-if="groupName == null" class="box">
      <form class="" @submit="onSubmitBatchOptions">
        <div class="field is-grouped">
          <b-field label="Name">
            <b-input v-model.trim="name"> </b-input>
          </b-field>
          <b-field v-if="false" label="Customer User ID">
            <b-input v-model.trim="customerUserId"> </b-input>
          </b-field>
          <TdInputGroup v-if="getMonitoring" :group.sync="group" :newButton="false" :includeAll="true" />
          <b-field v-if="false" label="Status">
            <b-select v-model="status">
              <option value=""></option>
              <option value="UPLOADED">UPLOADED</option>
              <option value="LOADED">LOADED</option>
              <option value="REJECTED">REJECTED</option>
            </b-select>
          </b-field>
          <div class="buttons">
            <button type="submit" class="button is-primary" id="next_button" @click="onSubmitBatchOptions">Search</button>
            <button type="reset" class="button is-default" id="clear_button" :disabled="waitingOnServer" @click="formReset()">Clear</button>
          </div>
        </div>
      </form>
    </section>
    <TdPanel panelId="search_results" panelTitle="Batch File List">
      <div class="panel-body">
        <b-table
          :data="batch.list"
          :striped="true"
          :current-page.sync="currentPage"
          :loading="isBusy"
          narrowed
          :paginated="true"
          :pagination-simple="false"
          :per-page="perPage"
          :default-sort-direction="defaultSortDirection"
          default-sort="created_at"
          :opened-detailed="defaultOpenedDetails"
          detailed
          detail-key="batch_id"
        >
          <template v-slot:empty>
            <section class="section">
              <div class="content has-text-grey has-text-centered">
                <p v-if="batch.isLoading">Getting batches.</p>
                <p v-else>
                  <b-message type="is-info">
                    No results to display. To update results, specify search criteria and click Search above.
                  </b-message>
                </p>
              </div>
            </section>
          </template>
          <template v-slot="props">
            <b-table-column field="batch_id" label="Batch ID" sortable numeric>
              <router-link v-if="props.row.isMonitoring" :to="{ name: 'batch-view', query: { id: props.row.batch_id, monitoring: props.row.isMonitoring } }">M{{ props.row.batch_id }}</router-link>
              <router-link v-else :to="{ name: 'batch-view', query: { id: props.row.batch_id, monitoring: props.row.isMonitoring } }">{{ props.row.batch_id }}</router-link>
            </b-table-column>
            <b-table-column v-if="false" field="customer_id" label="Customer ID" sortable>
              {{ props.row.customer_id }}
            </b-table-column>
            <b-table-column field="name" label="Name" sortable>
              {{ props.row.name }}
            </b-table-column>
            <b-table-column :visible="getMonitoring" field="group" label="Group" sortable>
              {{ props.row.group }}
            </b-table-column>
            <b-table-column v-if="false" label="Frequency">
              <span v-if="props.row.isMonitoring">{{ td_constant.TYPH_DATA_FREQUENCY_RECURRING }}</span>
              <span v-else>{{ td_constant.TYPH_DATA_FREQUENCY_ONE_TIME }}</span>
            </b-table-column>
            <b-table-column field="created_at" label="Created At" sortable>
              {{ props.row.created_at | shortNoTz }}
            </b-table-column>
            <b-table-column field="group_status" label="Group Status" sortable>
              {{ props.row.group_status }}
            </b-table-column>
            <b-table-column field="status" label="Batch Status" sortable>
              {{ props.row.status }}
            </b-table-column>
            <b-table-column label="Submitted By">
              {{ props.row.submitted_by }}
            </b-table-column>
          </template>
          <template v-slot:detail="props">
            <BatchDetail :batchId="props.row.batch_id" :batch="props.row" :monitoring="props.row.isMonitoring" />
          </template>
        </b-table>
      </div>
    </TdPanel>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import BatchDetail from '@/components/bryce/BatchDetail.vue'

export default {
  props: {
    getMonitoring: {
      type: Boolean,
      default: true
    },
    getOnetime: {
      type: Boolean,
      default: true
    },
    groupName: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      defaultSortDirection: 'desc',
      currentPage: 1,
      perPage: 50,
      perPageDownloadCount: Number.MAX_SAFE_INTEGER,
      defaultOpenedDetails: [],
      group: '',
      customerUserId: null,
      name: null,
      status: '',
      waitingOnServer: false
    }
  },
  computed: {
    ...mapState({
      batch: state => state.batch
    }),
    ...mapGetters('layout', ['intuitiveUI']),
    ...mapGetters('auth_user', ['currentUser', 'hasAdmin']),
    ...mapGetters('batch', ['isBusy']),
    ...mapState(['td_constant'])
  },
  components: {
    BatchDetail
  },
  created() {
    // this.$store.dispatch('batch/clearList', {})
    if (this.groupName != null) {
      this.fetchBatches({ ifEmpty: false })
    }
  },
  methods: {
    formReset() {
      this.name = null
      this.group = ''
      this.customerUserId = null
      this.status = null
      this.waitingOnServer = false
      this.$store.dispatch('batch/clearList')
    },
    fetchBatches(options) {
      this.waitingOnServer = true
      if (!options.ifEmpty || this.$store.getters['batch/batchsCountTotal'] == 0) {
        this.$store.dispatch('batch/clearList')
        let getMonitoring = this.getMonitoring
        let getOnetime = this.getOnetime
        if (this.groupName != null) {
          options.group = this.groupName
        }
        this.$store
          .dispatch('batch/fetchBatches', {
            searchOptions: {
              // id: +options.id,
              name: options.name,
              customer_user_id: +options.customerUserId,
              group: options.group,
              status: options.status,
              getMonitoring: getMonitoring,
              getOnetime: getOnetime,
              page: 1,
              page_count: this.perPageDownloadCount
            }
          })
          .then(() => {
            this.waitingOnServer = false
          })
      }
    },
    onGetBatches(options) {
      this.fetchBatches({
        ifEmpty: options.ifEmpty,
        // id: +this.searchOptions.id,
        name: this.name,
        customer_user_id: +this.customerUserId,
        group: this.group,
        status: this.status
      })
    },
    onSubmitBatchOptions(e) {
      e.preventDefault()
      this.onSubmitBatch()
    },
    onSubmitBatch() {
      this.fetchBatches({
        // id: +this.searchOptions.id,
        name: this.name,
        customer_user_id: +this.customerUserId,
        group: this.group,
        status: this.status
      })
    }
  }
}
</script>

<style scoped></style>
